import React from 'react';

const Hero: React.FC = () => {
  return (
    <div className="hero-container relative w-full h-screen flex items-center justify-center text-center bg-gradient-to-r from-green-400 via-blue-500 to-purple-600">
      {/* Waves Animation */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="wave top-0 left-0 w-full h-32 opacity-50" style={{ animationDelay: '0s' }}></div>
        <div className="wave top-16 left-0 w-full h-32 opacity-50" style={{ animationDelay: '2s' }}></div>
        <div className="wave top-32 left-0 w-full h-32 opacity-50" style={{ animationDelay: '4s' }}></div>
      </div>

      {/* Spline Background */}
      <div className="absolute inset-0 z-0">
        <iframe
          src="https://my.spline.design/purpleweb-aa0be67b932de772a90389c6d0f26103/"
          frameBorder="0"
          className="w-full h-full"
          allowFullScreen
        ></iframe>
      </div>

      <div className="content-container relative z-10 bg-gray-900 bg-opacity-70 rounded-lg p-8 shadow-lg">
        <div className="flex justify-center mb-4">
          <img src="/assets/price4evLogo4.png" alt="Natura EV Logo" className="max-w-full h-auto" style={{ width: '250px' }} />
        </div>
        <p className="text-customRed italic text-lg mb-4">(631) 306-4602</p>
        <h2 className="text-customRed text-2xl font-bold mb-6">Sustainable Charging, Unlimited Potential</h2>
        <a href="/services" className="bg-customRed hover:bg-green-700 text-white font-bold py-3 px-6 rounded-full transition-transform transform hover:scale-105 duration-300">Explore Services</a>
      </div>
    </div>
  );
};

export default Hero;
