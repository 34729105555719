import React, { useState } from 'react';

const TabbedContent: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>('');

  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName);
  };

  return (
    <div className="container mx-auto px-4 py-12">
      <div className="flex justify-center space-x-4 mb-4">
        <button
          onClick={() => handleTabClick('rebates')}
          className={`w-full md:w-auto py-2 px-4 text-white font-semibold rounded-lg ${selectedTab === 'rebates' ? 'bg-blue-700' : 'bg-blue-500'}`}
        >
          Rebates
        </button>
        <button
          onClick={() => handleTabClick('ev101')}
          className={`w-full md:w-auto py-2 px-4 text-white font-semibold rounded-lg ${selectedTab === 'ev101' ? 'bg-blue-700' : 'bg-blue-500'}`}
        >
          EV101
        </button>
      </div>

      {selectedTab === 'rebates' && (
        <div>
          <h2 className="text-xl font-bold text-center md:text-left">Select a Rebate Program:</h2>
          <ul className="list-disc pl-5">
            <li>MakeReady Program</li>
            <li>Another Rebate Program</li>
            {/* Add more as needed */}
          </ul>
        </div>
      )}

      {selectedTab === 'ev101' && (
        <div>
          <h2 className="text-xl font-bold text-center md:text-left">EV101 Installation Info</h2>
          <p>Here is detailed information about EV charger installation...</p>
        </div>
      )}
    </div>
  );
};

export default TabbedContent;
