import React from 'react';
import { Link } from 'react-router-dom';

// Define the type for the rebate programs
interface RebateProgram {
  name: string;
  description: string;
  path: string;
}

// Rebate programs data
const rebatePrograms: RebateProgram[] = [
  {
    name: 'MakeReady Program',
    description: 'Can cover up to 100% of the make-ready installation costs at sites qualifying under specific conditions, particularly in areas that support wider public accessibility.',
    path: '/makeready'
  },
  {
    name: 'Nyserda',
    description: 'Provides rebates that can cover a significant portion of the equipment and installation costs, depending on the type and capacity of the charger.',
    path: '/nyserda'
  },
  {
    name: 'Nyserda Financing',
    description: 'Offers low-interest loans that can be paired with grants and rebates to reduce upfront costs.',
    path: '/nyserda-financing'
  },
  {
    name: 'OptimizEV',
    description: 'Offers monthly incentives for participating and adhering to recommended charging times, along with initial signup bonuses.',
    path: '/optimizev'
  },
  {
    name: 'PSEG Long Island Charger Rebates',
    description: 'Provides a rebate of up to $500 for purchasing and installing a qualified Level 2 charger.',
    path: '/pseg-rebates'
  },
  {
    name: 'Renewable Energy Tax Credit Bridge Loan',
    description: 'Offers loans that cover up to the amount of expected federal and state tax credits, which are to be repaid upon receipt of the tax credits.',
    path: '/renewable-energy-tax-credit'
  },
  {
    name: 'Smart Energy Loan',
    description: 'Provides unsecured, low-interest loans that can be repaid through an on-bill recovery mechanism, allowing repayment through the utility bill.',
    path: '/smart-energy-loan'
  }
];

// RebateHero component
const RebateHero: React.FC = () => {
  return (
    <div className="bg-gray-200 p-10 text-center">
      <h1 className="text-4xl font-bold mb-6">NY Rebate Programs</h1>
      <h2 className="text-2xl mb-6">Rebates can cover up to 100% of installation costs!*</h2>
      <p className="text-1xl font-bold mb-6">Rebates can cover up to 100% of installation costs!*</p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {rebatePrograms.map((program) => (
          <div key={program.name} className="bg-white rounded-lg p-4 shadow-lg">
            <h2 className="text-2xl font-semibold">{program.name}</h2>
            <p className="mb-4">{program.description}</p>
            <Link to={program.path} className="text-blue-500 hover:text-blue-700 transition duration-300">Learn More</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RebateHero;
