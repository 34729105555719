import React from 'react';
import { Link } from 'react-router-dom';

const HomeEVManufacturersSection: React.FC = () => {
  return (
    <section className="container mx-auto px-4 py-12">
      <div className="bg-gray-50 p-6 rounded-lg shadow-lg text-center">
        <h2 className="text-3xl font-bold mb-6">Explore Top EV Charger Manufacturers</h2>
        <p className="text-lg mb-6">
          Discover reliable and efficient EV charging solutions from leading manufacturers.
        </p>
        <div className="flex justify-center mb-6">
          <Link to="/manufacturers">
            <img src="/assets/ManufacturersHomeLink.png" alt="EV Manufacturers" className="max-w-full h-auto rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300" />
          </Link>
        </div>
        <Link to="/manufacturers" className="inline-block bg-blue-600 text-white font-semibold py-3 px-6 rounded-lg hover:bg-blue-700 transition duration-300">
          View All Manufacturers
        </Link>
      </div>
    </section>
  );
};

export default HomeEVManufacturersSection;
