// src/Types/rebatesContent.ts

export interface SubSectionType {
    subHeading: string;
    content: string;
  }
  
  export interface SectionType {
    heading: string;
    content?: string;
    subSections?: SubSectionType[];
  }
  
  export interface RebatesContentType {
    title: string;
    sections: SectionType[];
  }
  
  export const rebatesContent: RebatesContentType = {
    title: "Rebates and Incentives for EV Charging in New York",
    sections: [
      {
        heading: "Introduction",
        content: "As the push towards electric vehicles (EVs) gains momentum in New York, both the state government and various local entities are offering an array of rebates and incentives designed to accelerate the adoption of EV technology. These financial incentives are crucial for individuals and businesses considering the installation of EV charging stations, as they significantly reduce the upfront costs and improve the return on investment."
      },
      {
        heading: "New York State EV Charging Incentives",
        subSections: [
          {
            subHeading: "NYSERDA Incentives",
            content: "NYSERDA provides funding through programs like the Charge NY initiative, which aims to make EV charging stations more accessible. This program offers grants that cover up to 80% of the cost to purchase and install EV charging stations. The Drive Clean Rebate offers up to $2,000 off at the point of sale for buyers of electric vehicles, complementing the charging station incentives by making the vehicles themselves more affordable."
          },
          {
            subHeading: "Tax Credits",
            content: "New York offers a state tax credit up to $5,000 for the installation of electric vehicle charging stations. This is available for both residential and commercial installations, providing a direct reduction in the tax payable by the applicant."
          },
          {
            subHeading: "Utility Company Programs",
            content: "Many of New York’s utility companies offer additional incentives. For instance, Con Edison customers can avail themselves of special rates and rebates for installing EV charging stations, as well as reduced tariffs for electricity used to charge electric vehicles during off-peak hours."
          }
        ]
      },
      {
        heading: "Local Incentives",
        content: "Many local governments within New York State offer their own incentives, including substantial support from New York City through the NYC Clean Fleet initiative and grants from Westchester County for public charging stations."
      },
      {
        heading: "Commercial Incentives",
        content: "For businesses, the opportunity to install EV charging stations is supported by potential cost savings and the ability to attract eco-conscious customers, enhancing property value and corporate image as a leader in sustainability."
      },
      {
        heading: "Conclusion",
        content: "Investing in EV charging infrastructure in New York is increasingly viable and attractive, thanks to a robust framework of rebates and incentives. These financial supports lower the barrier to entry, making it feasible for more New Yorkers to adopt electric vehicles and for businesses to accommodate this growing market."
      }
    ]
  };
  