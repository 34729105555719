// src/types/chargerSelectionData.ts
export interface ChargerSelectionSection {
  heading: string;
  points: string[];
}

export interface ChargerSelectionData {
  title: string;
  sections: ChargerSelectionSection[];
}

export const chargerSelectionData: ChargerSelectionData = {
  title: "Selecting the Right Charger",
  sections: [
    {
      heading: "Charging Speed",
      points: [
        "Level 1 Chargers: Provide about 4-5 miles of range per hour, using a standard 120-volt outlet.",
        "Level 2 Chargers: Provide about 25-30 miles of range per hour, using a 240-volt outlet.",
        "DC Fast Chargers: Can charge to 80% in as little as 20 minutes, using direct current (DC)."
      ]
    },
    {
      heading: "Connector Types",
      points: [
        "SAE J1772 (Type 1): Common in all non-Tesla American and European EVs.",
        "CCS (Combined Charging System): Offers fast charging for a wide range of vehicles.",
        "CHAdeMO: Used by Japanese manufacturers for fast charging.",
        "Tesla Supercharger: High-speed DC charging exclusive to Tesla vehicles."
      ]
    },
    {
      heading: "Network Capabilities",
      points: [
        "Smart Chargers: Allow remote monitoring and control via connectivity options like Wi-Fi or Bluetooth.",
        "Non-networked Chargers: Basic, often cheaper and simpler but lack remote features."
      ]
    },
    {
      heading: "Ease of Installation and Maintenance",
      points: [
        "Consider the installation ease and maintenance requirements, which can vary widely."
      ]
    },
    {
      heading: "Cost and Incentives",
      points: [
        "Assess the charger cost and installation, along with potential rebates or tax credits."
      ]
    }
  ]
};
