// src/components/ChargerSelection.tsx
import React from 'react';
import { chargerSelectionData, ChargerSelectionSection } from '../Types/chargerSelectionData';

const ChargerSelection: React.FC = () => {
  return (
    <div className="p-5 bg-white">
      <h1 className="text-xl font-bold">{chargerSelectionData.title}</h1>
      {chargerSelectionData.sections.map((section: ChargerSelectionSection, index: number) => (
        <div key={index} className="mt-4">
          <h2 className="text-lg font-semibold">{section.heading}</h2>
          <ul className="list-disc list-inside">
            {section.points.map((point: string, pointIndex: number) => (
              <li key={pointIndex}>{point}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default ChargerSelection;
