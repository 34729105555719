import React from 'react';
import { Link } from 'react-router-dom';

const Navbar: React.FC = () => {
  return (
    <nav className="bg-black text-customRed p-4 flex justify-between items-center">
      <Link to="/">
        <img src="/assets/price4evLogo4.png" alt="Natura EV Logo" className="h-12" />
      </Link>
      <Link to="/" className="hidden md:block">
        <p>Price4Ev.com</p> {/* Responsive visibility change */}
      </Link>
      <ul className="flex space-x-4">
        <li>
          <Link to="/areas" className="hover:text-gray-400 transition duration-300">
            Areas
          </Link>
        </li>
        <li>
          <Link to="/manufacturers" className="hover:text-gray-400 transition duration-300">
            Manufacturers
          </Link>
        </li>
        <li>
          <Link to="/rebates1" className="hover:text-gray-400 transition duration-300">
            Rebates
          </Link>
        </li>
        <li>
          <Link to="/ev101" className="hover:text-gray-400 transition duration-300">
            EV101
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
