// src/components/ChooseChargerSection.tsx
import React from 'react';
import { Link } from 'react-router-dom';

const ChooseChargerSection: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      <h2 className="text-3xl font-bold text-center mb-6">Choosing the Right Charger</h2>
      <p className="text-lg mb-6 text-center">
        Selecting the right EV charger can be overwhelming with so many options available. It's important to understand the different types of chargers and their benefits. Whether you need a fast charger for your business or a reliable home charger, we've got you covered.
      </p>
      <div className="flex justify-center mb-8">
        <Link to="/ev-chargers">
          <img src="/assets/manufacturerHero3.png" alt="EV Chargers" className="max-w-full h-auto rounded-lg shadow-lg" />
        </Link>
      </div>
      <p className="text-lg text-center">
        Click on the image to explore our comprehensive guide on EV chargers, including detailed information on each type, their benefits, and how to choose the best one for your needs.
      </p>
    </div>
  );
};

export default ChooseChargerSection;
