import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';
import CivilWorkSection from './components/CivilWorkSection';
import Home from './components/LandingPage/Home';
import Locations from './components/LandingPage/Locations';
import NavBar from './components/Nav/NavBar';
import Footer from './components/Nav/Footer';
import EVChargers from './components/EVChargerSelections/EVChargers';
import ChooseChargerHeroSection from './components/EVChargerSelections/ChooseChargerHeroSection';
import ManufacturersSection from './components/Manufacturers/ManufacturersSection';


const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/locations" element={<Locations />} />
            <Route path="/civil-work" element={<CivilWorkSection />} />
            <Route path="/choose-charger" element={<ChooseChargerHeroSection />} />
            <Route path="/ev-chargers" element={<ChooseChargerHeroSection />} />
            <Route path="/manufacturers" element={<ManufacturersSection />} />
            <Route path="/makeready" element={<div>MakeReady Program Details</div>} />
            <Route path="/nyserda" element={<div>Nyserda Program Details</div>} />
            <Route path="/nyserda-financing" element={<div>Nyserda Financing Details</div>} />
            <Route path="/optimizev" element={<div>OptimizEV Program Details</div>} />
            <Route path="/pseg-rebates" element={<div>PSEG Long Island Charger Rebates Details</div>} />
            <Route path="/renewable-energy-tax-credit" element={<div>Renewable Energy Tax Credit Bridge Loan Details</div>} />
            <Route path="/smart-energy-loan" element={<div>Smart Energy Loan Details</div>} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default App;
