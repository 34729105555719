import React from 'react';

interface CountyBoxProps {
  county: string;
  towns: string[];
}

const CountyBox: React.FC<CountyBoxProps> = ({ county, towns }) => {
  return (
    <div className="m-2 p-4 bg-gray-100 rounded shadow-lg w-1/4">
      <h2 className="font-bold text-xl mb-2">{county}</h2>
      <ul>
        {towns.map((town, index) => (
          <li key={index} className="text-gray-700 text-sm">{town}</li>
        ))}
      </ul>
    </div>
  );
};

export default CountyBox;
