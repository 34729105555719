

export interface Section {
    heading: string;
    points: string[];
  }
  
  export interface CivilWorkDataType {
    title: string;
    sections: Section[];
  }
  
  export const CivilWorkData: CivilWorkDataType = {
    title: "Civil Work Requirements",
    sections: [
      {
        heading: "Site Preparation",
        points: [
          "Site Assessment: Evaluate potential challenges like soil condition and drainage.",
          "Site Design: Design the layout considering vehicle access and electrical sources."
        ]
      },
      {
        heading: "Permits",
        points: [
          "Building Permits: Obtain necessary permits from your local building department.",
          "Electrical Permits: Required to ensure compliance with electrical codes."
        ]
      },
      {
        heading: "Compliance with Local Regulations",
        points: [
          "Zoning Laws: Ensure installation complies with local zoning laws.",
          "ADA Compliance: Ensure public charging stations are accessible according to ADA standards."
        ]
      },
      {
        heading: "Utility Coordination",
        points: [
          "Coordinate with local utilities to manage electrical supply and infrastructure."
        ]
      },
      {
        heading: "Safety Measures",
        points: [
          "Implement safety measures including signage, lighting, and barriers."
        ]
      },
      {
        heading: "Inspection and Approval",
        points: [
          "Local authority inspections are needed to approve the installation before use."
        ]
      }
    ]
  };
  