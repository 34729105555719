// src/components/ContentHero.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Service } from '../../Types/types';

const services: Service[] = [
  { name: "Rebates and Incentives", image: "./assets/LI.jpg", path: "/brooklyn", description: "Learn about the various rebates and incentives available for EV charging station installation to reduce your upfront costs and enhance ROI." },
  { name: "Civil Work", image: "./assets/nyc.webp", path: "/queens", description: "Understand the civil work requirements for installing EV charging stations, including site preparation, permits, and compliance with local regulations." },
  { name: "Electrical Work", image: "./assets/nj.webp", path: "/manhattan", description: "Get detailed information on the electrical work involved in setting up EV charging stations, from upgrading your power supply to ensuring safe connections." },
  { name: "Selecting the Right Charger", image: "./assets/delaware.jpg", path: "/staten-island", description: "Delaware's finest services." },
  { name: "Local Installation Services", image: "./assets/philly.jpg", path: "/the-bronx", description: "Find reliable local installation services for your EV charging stations. Ensure a professional setup with certified technicians." }
];

const ContentHero: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold text-center mb-8">Charging Forward with Confidence</h1>
      <div className="flex flex-col space-y-4">
        {services.map((service) => (
          <Link to={service.path} key={service.name} className="flex items-center bg-white shadow-lg rounded-lg overflow-hidden">
            <img src={service.image} alt={service.name} className="w-48 h-48 flex-shrink-0 object-cover" />
            <div className="p-4 flex flex-col justify-between">
              <h2 className="text-xl font-bold text-gray-800">{service.name}</h2>
              <p className="text-gray-600">{service.description}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ContentHero;
