import React from 'react';
import { Link } from 'react-router-dom';
import CountyBox from '../CountyBox'; // Corrected import path

// Define the type for the location data
interface Location {
  county: string;
  towns: string[];
}

// Locations data
const locations: Location[] = [
  {
    county: 'Nassau County',
    towns: ['Atlantic Beach', 'Baldwin', 'Bellmore', 'Bethpage', 'Cedarhurst', 'East Meadow', 'East Rockaway', 'Elmont', 'Floral Park', 'Franklin Square', 'Freeport', 'Garden City', 'Hempstead', 'Hewlett', 'Inwood']
  },
  {
    county: 'Town of North Hempstead',
    towns: ['Albertson', 'Carle Place', 'Floral Park', 'Great Neck', 'Greenvale', 'Kings Point', 'Manhasset', 'Mineola', 'New Hyde Park', 'North New Hyde Park', 'Old Westbury', 'Port Washington', 'Roslyn', 'Roslyn Heights', 'Sands Point', 'Westbury', 'Williston Park']
  },
  {
    county: 'Town of Oyster Bay',
    towns: ['Bayville', 'Bethpage', 'Brookville', 'East Norwich', 'Farmingdale', 'Glen Head', 'Glenwood Landing', 'Greenvale', 'Hicksville', 'Jericho', 'Locust Valley', 'Massapequa', 'Massapequa Park', 'Mill Neck', 'Old Bethpage', 'Old Westbury', 'Oyster Bay', 'Plainview', 'Sea Cliff', 'Syosset', 'Woodbury']
  },
  {
    county: 'Town of Babylon',
    towns: ["Amityville", "Babylon", "Copiague", "Deer Park", "Lindenhurst", "North Amityville", "North Babylon", "West Babylon", "Wyandanch"]
  },
  {
    county: "Town of Brookhaven",
    towns: ["Bellport", "Blue Point", "Brookhaven", "Calverton", "Centereach", "Center Moriches", "Coram", "East Moriches", "East Patchogue",
    "Eastport", "East Setauket", "Farmingville", "Holbrook", "Holtsville", "Lake Grove",
    "Manorville", "Mastic", "Mastic Beach", "Medford", "Middle Island", "Miller Place",
    "Moriches", "Mount Sinai", "North Patchogue", "Patchogue", "Port Jefferson",
    "Port Jefferson Station", "Ridge", "Rocky Point", "Ronkonkoma", "Selden",
    "Shirley", "Shoreham", "Sound Beach", "Stony Brook", "Upton", "Wading River", "Yaphank"]
  },
  {
    county: "Town of East Hampton",
    towns: ["Amagansett", "East Hampton", "Montauk", "Sag Harbor", "Wainscott"]
  },
  {
    county: "Town of Huntington",
    towns: ["Centerport", "Cold Spring Harbor", "COMMACK NY", "East Northport", "Greenlawn", "Huntington", "Huntington Station", "Lloyd Harbor", "Melville", "Northport"]
  },
  {
    county: "Town of Islip",
    towns: ["Bayport", "Bay Shore", "Bohemia", "Brentwood", "Brightwaters",
      "Central Islip", "East Islip", "Great River", "Hauppauge", "Holbrook",
      "Holtsville", "Islandia", "Islip", "Islip Terrace", "Oakdale",
      "Ocean Beach", "Ronkonkoma", "Sayville", "West Islip", "West Sayville"]
  },
  {
    county: "Town of Riverhead",
    towns: [
      "Aquebogue", "Calverton", "Jamesport", "Laurel", "Manorville",
      "Riverhead", "South Jamesport", "Wading River"]
  },
  {
    county: "Town of Shelter Island",
    towns: ["Shelter Island", "Shelter Island Heights"]
  },
  {
    county: "Town of Smithtown",
    towns: ["Commack", "Hauppauge", "Kings Park", "Nesconset", "Saint James", "Smithtown"]
  },
  {
    county: "Town of Southhampton",
    towns: ["Bridgehampton", "Eastport", "East Quogue", "Hampton Bays", "Quogue",
      "Remsenburg", "Sagaponack", "Sag Harbor", "Southampton", "Speonk",
      "Water Mill", "Westhampton", "Westhampton Beach"]
  },
  {
    county: "Town of Southold",
    towns: ["Cutchogue", "East Marion", "Fishers Island", "Greenport", "Laurel",
      "Mattituck", "New Suffolk", "Orient", "Peconic", "Southold"]
  }
];

const Locations: React.FC = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
      {locations.map((location, index) => (
        <CountyBox key={index} county={location.county} towns={location.towns} />
      ))}
    </div>
  );
};

export default Locations;
