// src/components/Rebates/RebatesPages/RebatesSection.tsx

import React from 'react';
import { rebatesContent, SectionType, SubSectionType } from '../../../Types/rebatesContent';

const RebatesSection: React.FC = () => {
  return (
    <div className="p-5 bg-white">
      <h1 className="text-xl font-bold">{rebatesContent.title}</h1>
      {rebatesContent.sections.map((section: SectionType, index: number) => (
        <div key={index} className="mt-4">
          <h2 className="text-lg font-semibold">{section.heading}</h2>
          {section.content && <p>{section.content}</p>}
          {section.subSections && section.subSections.map((sub: SubSectionType, subIndex: number) => (
            <div key={subIndex} className="mb-4">
              <h3 className="text-md font-semibold">{sub.subHeading}</h3>
              <p>{sub.content}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default RebatesSection;
