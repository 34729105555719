// src/components/ChooseChargerHeroSection.tsx
import React from 'react';
import { Link } from 'react-router-dom';

const ChooseChargerHeroSection: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-12 bg-gray-50">
      <h2 className="text-3xl font-bold text-center mb-6">Choosing the Right Charger</h2>
      <p className="text-lg mb-6 text-center">
        Selecting the right EV charger can be overwhelming with so many options available. It's important to understand the different types of chargers and their benefits. Whether you need a fast charger for your business or a reliable home charger, we've got you covered.
      </p>
      <div className="flex justify-center mb-8">
        <Link to="/ev-chargers">
          <img src="/assets/manufacturerHero3.png" alt="EV Chargers" className="max-w-sm h-auto rounded-lg shadow-lg" />
        </Link>
      </div>
      <div className="grid md:grid-cols-3 gap-6">
        <div className="bg-white p-4 shadow-md rounded-lg">
          <h3 className="text-xl font-semibold text-green-600">Level 1 EV Chargers</h3>
          <p className="text-sm">
            Level 1 chargers use a standard household outlet (120V) and provide about 4-5 miles of range per hour. Ideal for overnight charging at home.
          </p>
        </div>
        <div className="bg-white p-4 shadow-md rounded-lg">
          <h3 className="text-xl font-semibold text-green-600">Level 2 EV Chargers</h3>
          <p className="text-sm">
            Level 2 chargers require a 240V outlet and can provide about 25-30 miles of range per hour. Perfect for home use, workplaces, and public charging stations.
          </p>
        </div>
        <div className="bg-white p-4 shadow-md rounded-lg">
          <h3 className="text-xl font-semibold text-green-600">DC Fast Chargers</h3>
          <p className="text-sm">
            DC Fast Chargers can charge an EV to 80% in as little as 20 minutes. Ideal for commercial settings and public charging stations.
          </p>
        </div>
      </div>
      <p className="text-lg text-center mt-6">
        Click on the image above to explore our comprehensive guide on EV chargers, including detailed information on each type, their benefits, and how to choose the best one for your needs.
      </p>
    </div>
  );
};

export default ChooseChargerHeroSection;
