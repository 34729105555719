import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

const EV101: React.FC = () => {
  const [openSection, setOpenSection] = useState<string | null>(null);

  const toggleSection = (section: string) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <section className="py-8 px-4 bg-gray-50">
      <Helmet>
        <title>EV Charger Installation Options in Huntington - Level 1, 2, and DC Fast Chargers</title>
        <meta name="description" content="Explore your options for EV charger installations in Huntington, including Level 1, Level 2, and DC Fast Chargers. Find out costs, installation requirements, and more." />
        <meta name="keywords" content="EV charger installation, Level 1 charger, Level 2 charger, DC Fast Charger, Huntington EV charging" />
      </Helmet>
      <div id="ev101" className="max-w-6xl mx-auto">
        {/* Level 1 Chargers */}
        <div className="md:hidden">
          <button onClick={() => toggleSection('level1')} className="w-full text-left py-4 px-4 bg-green-200 mb-2 rounded-lg flex justify-between items-center">
            <h2 className="text-2xl font-semibold text-green-700">Level 1 EV Chargers</h2>
            <span>{openSection === 'level1' ? '▲' : '▼'}</span>
          </button>
          {openSection === 'level1' && (
            <div className="mb-4 px-4 py-2 bg-white rounded-lg shadow-md">
              <p>
                Level 1 chargers provide the most straightforward and cost-effective way to charge your EV. They
                can be used with a standard household outlet (120V) and do not typically require any additional
                installation of special charging equipment.
              </p>
              <div className="mt-4">
                <h3 className="text-xl font-semibold text-green-600">Permits</h3>
                <p>
                  In most cases, Level 1 charging at home won't require special permits since it uses existing
                  power outlets. However, always check with local regulations in Huntington or your specific area
                  to ensure compliance.
                </p>
                <h3 className="text-xl font-semibold text-green-600">Costs</h3>
                <p>
                  The primary cost associated with Level 1 chargers is the purchase price of the charger itself,
                  which typically ranges from $150 to $500. Since no additional installation is usually necessary,
                  it's the most economical option for home charging.
                </p>
                <h3 className="text-xl font-semibold text-green-600">Installation</h3>
                <p>
                  Installing a Level 1 charger is often as simple as plugging it into a standard wall outlet.
                  Ensure that the circuit is not overloaded and is in good condition to safely handle the charger.
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="hidden md:block">
          <h2 className="text-2xl font-semibold text-green-700 mb-4">Level 1 EV Chargers</h2>
          <p className="mb-4">
            Level 1 chargers provide the most straightforward and cost-effective way to charge your EV. They
            can be used with a standard household outlet (120V) and do not typically require any additional
            installation of special charging equipment.
          </p>
          <div className="grid md:grid-cols-3 gap-6 mb-8">
            <div>
              <h3 className="text-xl font-semibold text-green-600">Permits</h3>
              <p>
                In most cases, Level 1 charging at home won't require special permits since it uses existing
                power outlets. However, always check with local regulations in Huntington or your specific area
                to ensure compliance.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-green-600">Costs</h3>
              <p>
                The primary cost associated with Level 1 chargers is the purchase price of the charger itself,
                which typically ranges from $150 to $500. Since no additional installation is usually necessary,
                it's the most economical option for home charging.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-green-600">Installation</h3>
              <p>
                Installing a Level 1 charger is often as simple as plugging it into a standard wall outlet.
                Ensure that the circuit is not overloaded and is in good condition to safely handle the charger.
              </p>
            </div>
          </div>
        </div>

        <hr />

        {/* Level 2 Chargers */}
        <div className="md:hidden">
          <button onClick={() => toggleSection('level2')} className="w-full text-left py-4 px-4 bg-green-200 mb-2 rounded-lg flex justify-between items-center">
            <h2 className="text-2xl font-semibold text-green-700">Level 2 EV Chargers</h2>
            <span>{openSection === 'level2' ? '▲' : '▼'}</span>
          </button>
          {openSection === 'level2' && (
            <div className="mb-4 px-4 py-2 bg-white rounded-lg shadow-md">
              <p>
                Level 2 chargers offer a faster charging solution, operating at 240V and typically requiring a dedicated
                circuit. They are ideal for homeowners who need quicker charging cycles.
              </p>
              <div className="mt-4">
                <h3 className="text-xl font-semibold text-green-600">Permits</h3>
                <p>
                  Installing a Level 2 charger often requires a permit, as it involves upgrading your home’s electrical
                  system. Consult your local building authority for specific requirements and necessary documentation.
                </p>
                <h3 className="text-xl font-semibold text-green-600">Costs</h3>
                <p>
                  Level 2 chargers can cost between $500 and $700, with additional costs for installation which can
                  vary based on electrical upgrades and labor. Rebates and incentives may be available to help offset these costs.
                </p>
                <h3 className="text-xl font-semibold text-green-600">Installation</h3>
                <p>
                  Professional installation is recommended for Level 2 chargers to ensure safety and compliance with local
                  codes. It typically involves adding a new circuit breaker and running dedicated wiring to the charging location.
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="hidden md:block">
          <h2 className="text-2xl font-semibold text-green-700 mb-4">Level 2 EV Chargers</h2>
          <p className="mb-4">
            Level 2 chargers offer a faster charging solution, operating at 240V and typically requiring a dedicated
            circuit. They are ideal for homeowners who need quicker charging cycles.
          </p>
          <div className="grid md:grid-cols-3 gap-6">
            <div>
              <h3 className="text-xl font-semibold text-green-600">Permits</h3>
              <p>
                Installing a Level 2 charger often requires a permit, as it involves upgrading your home’s electrical
                system. Consult your local building authority for specific requirements and necessary documentation.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-green-600">Costs</h3>
              <p>
                Level 2 chargers can cost between $500 and $700, with additional costs for installation which can
                vary based on electrical upgrades and labor. Rebates and incentives may be available to help offset these costs.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-green-600">Installation</h3>
              <p>
                Professional installation is recommended for Level 2 chargers to ensure safety and compliance with local
                codes. It typically involves adding a new circuit breaker and running dedicated wiring to the charging location.
              </p>
            </div>
          </div>
        </div>

        <hr />

        {/* DC Fast Chargers */}
        <div className="md:hidden">
          <button onClick={() => toggleSection('dcfast')} className="w-full text-left py-4 px-4 bg-green-200 mb-2 rounded-lg flex justify-between items-center">
            <h2 className="text-2xl font-semibold text-green-700">DC Fast Chargers</h2>
            <span>{openSection === 'dcfast' ? '▲' : '▼'}</span>
          </button>
          {openSection === 'dcfast' && (
            <div className="mb-4 px-4 py-2 bg-white rounded-lg shadow-md">
              <p>
                DC Fast Chargers are the quickest way to charge EVs, providing a significant amount of charge in just 20-30 minutes. They are ideal for commercial settings or public charging stations.
              </p>
              <div className="mt-4">
                <h3 className="text-xl font-semibold text-green-600">Permits</h3>
                <p>
                  The installation of DC Fast Chargers typically requires significant electrical upgrades and thus, a permit is almost always necessary. Ensure compliance with local electrical codes and regulations.
                </p>
                <h3 className="text-xl font-semibold text-green-600">Costs</h3>
                <p>
                  DC Fast Chargers are more expensive, ranging from $10,000 to over $40,000, depending on the model and capabilities. The higher initial cost is offset by the rapid charging they provide.
                </p>
                <h3 className="text-xl font-semibold text-green-600">Installation</h3>
                <p>
                  Installing a DC Fast Charger requires professional installation by a qualified electrician or technician. It may involve upgrading your facility's transformer or service panel to handle the high power demand.
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="hidden md:block">
          <h2 className="text-2xl font-semibold text-green-700 mb-4">DC Fast Chargers</h2>
          <p className="mb-4">
            DC Fast Chargers are the quickest way to charge EVs, providing a significant amount of charge in just 20-30 minutes. They are ideal for commercial settings or public charging stations.
          </p>
          <div className="grid md:grid-cols-3 gap-6">
            <div>
              <h3 className="text-xl font-semibold text-green-600">Permits</h3>
              <p>
                The installation of DC Fast Chargers typically requires significant electrical upgrades and thus, a permit is almost always necessary. Ensure compliance with local electrical codes and regulations.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-green-600">Costs</h3>
              <p>
                DC Fast Chargers are more expensive, ranging from $10,000 to over $40,000, depending on the model and capabilities. The higher initial cost is offset by the rapid charging they provide.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-green-600">Installation</h3>
              <p>
                Installing a DC Fast Charger requires professional installation by a qualified electrician or technician. It may involve upgrading your facility's transformer or service panel to handle the high power demand.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EV101;
