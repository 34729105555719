import React from 'react';
import { Link } from 'react-router-dom';

// Define the type for the rebate programs
interface RebateProgramNJ {
  name: string;
  description: string;
  path: string;
}

// Rebate programs data
const rebateProgramsNJ: RebateProgramNJ[] = [
  {
    name: 'PSE&G Electric Vehicle Charging Program',
    description: 'This program offers a rebate of $1,500 per charger, which may cover up to 100% of the make-ready costs. The rebate is applicable for Level 2 EV chargers that are networked.',
    path: '/psgNJ'
  },
  {
    name: 'Atlantic City Electrics ACE EVsmart Program',
    description: 'This program provides a rebate of $1,000 per charger and covers up to 50% of the make-ready costs. It requires the charger to be networked and be part of an approved list of chargers.',
    path: '/EVsmartProgram'
  },
  {
    name: 'Rockland Electric Charger Ready for Home Program',
    description: 'Rockland Electric Charger Ready for Home Program',
    path: '/RocklandChargerReadyProgram'
  },
  {
    name: 'Federal Tax Incentive (30C Federal Tax Credit)',
    description: 'Additionally, there is a federal tax credit available that offers 30% of the cost of installing an EV charger, up to a maximum of $1,000. To qualify, installations must be completed at properties located in approved census tracts.',
    path: '/30CFederalTaxCredit'
  }
];

// RebateHeroNJ component
const RebateHeroNJ: React.FC = () => {
  return (
    <div className="bg-gray-200 p-10 text-center">
      <h1 className="text-4xl font-bold mb-6">NJ Rebate Programs</h1>
      <h2 className="text-2xl mb-6">Rebates can cover up to 100% of installation costs!*</h2>
      <p className="text-1xl font-bold mb-6">Rebate eligibility varies for each location. Call us to learn more!</p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {rebateProgramsNJ.map((program) => (
          <div key={program.name} className="bg-white rounded-lg p-4 shadow-lg">
            <h2 className="text-2xl font-semibold">{program.name}</h2>
            <p className="mb-4">{program.description}</p>
            <Link to={program.path} className="text-blue-500 hover:text-blue-700 transition duration-300">Learn More</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RebateHeroNJ;
