import React from 'react'
import CivilWorkSection from '../CivilWorkSection'
import RebateHero from './RebateHero'
import Hero from './Hero'
import RebateHeroNJ from './RebateHeroNJ'
import ExploreNeighborhoods from './ExploreNeighborhoods'
import ChargerSelection from '../ChargerSelection'
import ContentHero from './ContentHero'
import EV101 from '../EVChargerSelections/EV101'
import RebatesSection from '../Rebates/RebatesPages/RebatesSection'
import TabbedContent from '../TabbedContent'
import ChooseChargerSection from '../EVChargerSelections/ChooseChargerSection'
import HomeEVManufacturersSection from '../Manufacturers/HomeEVManufacturersSection'

const Home = () => {
  return (
    <div>
      <Hero />
      <RebateHero />
      <HomeEVManufacturersSection />
      <ChooseChargerSection />
      <EV101 />
      <ContentHero />
      <CivilWorkSection />
      
      <RebateHeroNJ />
      <ExploreNeighborhoods />
      <ChargerSelection />
      <RebatesSection />
      <TabbedContent />
    </div>
  )
}

export default Home