import React from 'react';
import { Link } from 'react-router-dom';

const manufacturers = [
  {
    name: 'EverCharge',
    image: '/assets/EverchargeLogo2.png',
    path: '/evercharge',
    description: 'Reliable and efficient EV charging solutions.',
  },
  {
    name: 'Tesla',
    image: '/assets/TeslaLogo.png',
    path: '/tesla',
    description: 'Innovative EV charging solutions by Tesla.',
  },
  {
    name: 'Enel X Way',
    image: '/assets/EnelwayLogo.png',
    path: '/enelx',
    description: 'Smart EV charging solutions by Enel X Way.',
  },
  {
    name: 'Flo',
    image: '/assets/FloLogo2.png',
    path: '/flo',
    description: 'Comprehensive EV charging solutions by Flo.',
  },
  {
    name: 'ChargePoint',
    image: '/assets/cplogo2.png',
    path: '/chargepoint',
    description: 'Extensive EV charging network by ChargePoint.',
  },
  {
    name: 'Leviton',
    image: '/assets/LevitonLogo2.png',
    path: '/leviton',
    description: 'Reliable and safe EV charging solutions by Leviton.',
  },
];

const ManufacturersSection: React.FC = () => {
  return (
    <section className="container mx-auto px-4 py-12">
      <div className="bg-gray-50 p-6 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-center mb-6">EV Charger Manufacturers</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {manufacturers.map((manufacturer, index) => (
            <Link to={manufacturer.path} key={index} className="block bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
              <div className="relative h-56">
                <img src={manufacturer.image} alt={manufacturer.name} className="w-full h-full object-cover" />
              </div>
              <div className="p-4">
                <h3 className="text-xl font-semibold mb-2">{manufacturer.name}</h3>
                <p className="text-gray-600">{manufacturer.description}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ManufacturersSection;
